<template>
<div>
    <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{ $t('label.listActivity') }}</b>
        </CCardHeader>
        <CCardBody>
            <CustomTabs :active-tab="tabChange" @update:activeTab="handleTab">
              <CustomTab :title="$t('label.portActivity')" >
                <Activity :tabIndex="tabChange" />
              </CustomTab>
               <CustomTab :title="$t('label.ClientType')" icon="user">
                <TipoCliente :tabIndex="tabChange" />
              </CustomTab>
               <CustomTab :title="$t('label.OperationType')" >
                <TipoOperacion :tabIndex="tabChange" />
              </CustomTab>
            </CustomTabs>
       </CCardBody>
    </CCard>
</div>
</template>

<script>
import { mapState } from "vuex";
import Activity from "@/pages/actividad/ActividadPortuaria/index";
import TipoCliente from "@/pages/actividad/TipoCliente/index";
import TipoOperacion from "@/pages/actividad/TipoOperacion/index";
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';


//DATA
function data() {
  return {
    tabChange: 0,
  };
}

//METHOD
  function handleTab(tab) {
      this.tabChange = tab;
  }


// COMPUTED


export default {
  name: "activity-list",
  data,
  components: {
    Activity,
    TipoCliente,
    TipoOperacion,
    CustomTabs,
    CustomTab,
  },
  methods: {
    handleTab,
  },
  watch: {

  },
  computed: {
    ...mapState({
      branch: (state) => state.auth.branch,
      user: (state) => state.auth.user,
    }),
  },
};
</script>
<style scoped>

</style>
